.menu-open {
    overflow: hidden;
  }

  #onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info, #onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
    background: #303030 !important;
  }

  .ot-pc-footer-logo {
    background: #303030 !important;
  }
  
  #onetrust-pc-sdk .ot-plus-minus span {
    background: #b2f100 !important;
  }
  
  #onetrust-pc-sdk .ot-always-active {
    color: #b2f100 !important;
  }
  
  #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background-color: #fff !important;
    border: 1px solid #fff !important;
  }

  #onetrust-consent-sdk #onetrust-pc-sdk :focus, #onetrust-consent-sdk #onetrust-pc-sdk .ot-vlst-cntr > a:focus {
    border: 1px solid #fff !important;
}
  
  #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
    background-color: #b2f100 !important;
  }

  .ot-close-icon{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)!important;
  }

  #onetrust-pc-sdk #filter-btn-handler {
    background-color: #9cd200;
  }
  